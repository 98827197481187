import { createRouter, createWebHashHistory } from "vue-router";

import { nextTick } from "vue";

let routes = [
  {
    path: "/",
    redirect: { name: "Home" },
    component: () =>
      import(/* webpackChunkName: "gamelist" */ "@/views/KoFiBody"),
    children: [
      {
        path: "/games",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "gamelist" */ "@/views/MainMenuPage"),
      },
      {
        path: "/dmwc/:roomCode?",
        name: "DontMessWithCthulhu",
        props: true,
        meta: { title: "Don't Mess With Cthulhu" },
        component: () =>
          import(
            /* webpackChunkName: "crosscase" */ "@/views/DontMessWithGame"
          ),
      },
      {
        path: "/wlc/:roomCode?",
        name: "WinLoseCthulhu",
        props: true,
        meta: { title: "Win, Lose, Cthulhu" },
        component: () =>
          import(/* webpackChunkName: "crosscase" */ "@/views/WinLoseGame"),
      },
      {
        path: "/afc/:roomCode?",
        name: "FakeCultistComes",
        props: true,
        meta: { title: "A Fake Cultist Comes to Innsmouth" },
        component: () =>
          import(/* webpackChunkName: "crosscase" */ "@/views/FakeCultistGame"),
      },
    ],
  },
  {
    path: "/hangcliff",
    name: "HangCliff",
    meta: { title: "Hangcliff Daily" },
    component: () =>
      import(/* webpackChunkName: "gamelist" */ "@/views/HangCliff"),
  },
  {
    path: "/synopsle/:archiveNumber?",
    name: "synopsle",
    props: true,
    meta: { title: "Synopsle Daily" },
    component: () =>
      import(/* webpackChunkName: "crosscase" */ "@/views/Synopsle"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "Page Not Found" },
    component: () => import(/* webpackChunkName: "gamelist" */ "@/views/404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "404" },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { name: "404" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const DEFAULT_TITLE = "Mythos Games by Glentopher";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
