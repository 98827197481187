import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
import ConfirmationService from "primevue/confirmationservice";
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primevue/resources/themes/arya-purple/theme.css";
import "/node_modules/primeflex/primeflex.css";
import { createHead } from "unhead";

// Create a global head instance
createHead();
createApp(App)
  .use(PrimeVue, { ripple: true })
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(ToastService)
  .use(ConfirmationService)
  .directive("tooltip", Tooltip)
  .mount("#app");
